<template>
  <div class="edit-common">
    <edit-logo
      type="user"
      :is-round="true"
      :max-file-size="5"
      @updated="updateProfileInfo"
    >
      <user-avatar :src="profile.avatarUrl" />
    </edit-logo>

    <BaseForm @submit="save">
      <div class="inline-group">
        <BaseInput
          v-model="nickname"
          :label="$t('players.nickname_label')"
          :placeholder="$t('players.nickname_platform')"
          :disabled="!canEditProfile"
          :status="status"
          @input="skipError"
        />
        <pvp-btn
          type="submit"
          :disabled="!canEditProfile"
          :is-loading="isLoading"
        >
          {{ $t('save.single') }}
        </pvp-btn>
      </div>
      <div class="description" :class="status">{{ description }}</div>
    </BaseForm>
  </div>
</template>

<script>
import EditLogo from '@components/BaseComponents/EditLogo.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';

export default {
  name: 'ProfileEditCommon',
  components: {
    EditLogo,
    BaseInput,
    BaseForm,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      nickname: '',
      error: '',
      status: '',
    };
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfile']),

    profile() {
      return this.getCurrentProfile;
    },

    canEditProfile() {
      return this.profile?.abilities?.canEditProfile;
    },

    description() {
      if (this.status === 'error') {
        return this.error;
      }

      if (this.status === 'success') {
        return this.$t('players.nickname_success');
      }

      if (this.canEditProfile) {
        return this.$t('players.nickname_tooltip');
      }

      return this.$t('profile.edit_warnings_tournament_text', {
        link: this.$t('profile.edit_warnings_tournament_link'),
      });
    },
  },
  created() {
    const profile = this.getCurrentProfile;
    this.nickname = profile.nick;
  },
  methods: {
    ...mapActions('profile', [
      'fetchProfileUpdate',
      'updateCurrentProfile',
    ]),

    updateProfileInfo(data) {
      this.updateCurrentProfile({ avatarUrl: data?.fileUrl });
      // eslint-disable-next-line no-underscore-dangle
      window.__GEMS.custom_avatar = data?.fileUrl;
      window.__GEM.renderHeader();
    },

    skipError() {
      this.error = '';
      this.status = '';
    },

    save() {
      this.skipError();
      this.isLoading = true;

      this.fetchProfileUpdate({
        nick: this.nickname,
      })
        .then(() => {
          this.status = 'success';
          // eslint-disable-next-line no-underscore-dangle
          window.__GEMS.custom_nickname = this.nickname;
          window.__GEM.renderHeader();
          setTimeout(this.skipError, 3000);
        })
        .catch(({ error = [] }) => {
          this.status = 'error';
          this.error =
            error?.nick.replace(/&quot;/g, '"') ||
            this.$t('errors.unknown');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-common {
  @include min-tablet() {
    display: flex;
    align-items: center;
  }

  @include max-tablet() {
    text-align: center;
  }
}

.user-avatar {
  &,
  &.inline {
    width: 130px;
    height: 130px;
  }
}

.pvp-form {
  font-size: 14px;
  flex-grow: 1;
  @include min-tablet() {
    max-width: 400px;
    margin-left: 30px;
  }
  @include max-tablet() {
    text-align: left;
    margin-top: 10px;
  }
}

.button {
  margin-left: 8px;
  width: 90px;
  flex-shrink: 0;
}

.inline-group {
  display: flex;
  align-items: flex-end;
}

.description {
  margin-top: 6px;
  font-size: em(13px);
  color: rgba(white, 0.4);

  &.success {
    color: $dark-pastel-green;
  }

  &.error {
    color: $orangey-red;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team-rating"},[(_vm.isLoading)?_c('loader'):(_vm.tournaments.length)?[_c('div',{staticClass:"pvp-table stripped adaptive"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-row"},[_c('cell-header',{attrs:{"t":"date.single"}}),_c('cell-header',{attrs:{"t":"tournaments.single"}}),_c('cell-header',{attrs:{"t":"global.result"}}),_c('cell-header')],1)]),_c('div',{staticClass:"table-body"},_vm._l((_vm.tournaments),function(item,index){return _c('div',{key:index,staticClass:"table-row"},[_c('cell-simple',{staticClass:"tournament"},[_c('game-tag',{attrs:{"id":item.idGame}}),_c('BaseLink',{attrs:{"to":{
                name: 'tournament',
                params: { id: item.urlCode || item.id },
              }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('cell-date',{staticClass:"date",attrs:{"ts":item.tsStartRun,"format":"dd_MM_YYYY_HH_mm"}}),_c('cell-simple',{staticClass:"result",attrs:{"t":"global.result"}},[_c('div',{domProps:{"innerHTML":_vm._s(item.statusName)}})]),_c('cell-simple',{staticClass:"more"},[_c('BaseLink',{staticClass:"table-link",attrs:{"to":{
                name: 'tournament',
                params: { id: item.urlCode || item.id },
              },"icon-right":"angle-right"}},[_vm._v(" "+_vm._s(_vm.$t('global.more'))+" ")])],1)],1)}),0)]),(_vm.showPagination)?_c('pvp-pagination',{attrs:{"params":_vm.paginationParams,"disabled":_vm.isLoading},on:{"onchange":_vm.setPage}}):_vm._e()]:_c('div',{staticClass:"tournaments-placeholder"},[_vm._v(" "+_vm._s(_vm.$t('teams.placeholders_tournaments'))+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
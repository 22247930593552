<template>
  <div class="profile-ratings">
    <UserRatings :profile-hash="hash" />
  </div>
</template>

<script>
import UserRatings from '@components/RatingComponents/UserRatings.vue';

export default {
  name: 'Ratings',
  components: {
    UserRatings,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <main-layout class="padded">
    <div class="pvp-container">
      <box v-if="isSuccess" class="placeholder">
        <icon name="pvp-swords" />
        <InfoBox class="text" variant="success">
          {{ $t('profile.confirmation_email_success') }}
        </InfoBox>
      </box>
      <box v-else class="placeholder">
        <loader />
        <div class="text">
          {{ $t('profile.confirmation_email_inprogress') }}
        </div>
      </box>
    </div>
  </main-layout>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'EmailConfirmation',
  components: {
    Icon,
    InfoBox,
  },
  page() {
    return {
      title: this.$t('profile.confirmation_email_title'),
    };
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isSuccess: null,
  }),
  created() {
    api
      .post('/profile/confirm', {
        token: this.hash,
      })
      .then(({ success }) => {
        this.isSuccess = success;
      })
      .catch(() => {
        this.$router.replace({ name: 'not-found' });
      });
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 12px;
}

.icon {
  font-size: 30px;
  color: $dodger-blue;
}

.text {
  margin-top: 12px;
}

.button {
  margin-top: 20px;
}
</style>

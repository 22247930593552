<template>
  <div class="profile-jobfair">
    <loader v-if="isLoading" />
    <template v-else>
      <div class="profile-tabs">
        <h3>
          {{
            $t(`jobfair.search_my_${isTeam ? 'players' : 'teams'}`)
          }}
        </h3>

        <Button
          tag="button"
          size="regular"
          type="secondary"
          :loading="false"
          :text="
            $t(
              `jobfair.search_my_show_${
                isTeam ? 'teams' : 'players'
              }`,
            )
          "
          @click.native="updateType(isTeam ? 'resume' : 'vacancy')"
        >
        </Button>

        <Button
          tag="button"
          size="regular"
          type="secondary"
          :loading="false"
          :text="$t('jobfair.profile_add')"
          @click.native="toggleSettingsModal(true)"
        >
          <template #slotBefore>
            <icon name="plus" />
          </template>
        </Button>
      </div>
      <template v-if="list.length">
        <jobfair-box
          v-for="(profile, key) in list"
          :key="key"
          :type="type"
          :profile="profile"
          @edit="toggleSettingsModal"
          @pushed="fetchJobFair"
        />
      </template>
      <div v-else class="notfound">
        {{ $t('jobfair.notfound_my') }}
      </div>
    </template>
    <jobfair-settings-modal
      v-model="isModalOpen"
      :settings-options="profileEditSettings"
      @success="fetchJobFair"
    />
    <Pagination
      v-if="showPagination"
      :disabled="isLoading"
      :params="pagination"
      @onchange="updatePage"
    />
  </div>
</template>

<script>
import JobfairBox from '@components/Jobfair/JobfairBox.vue';
import JobfairSettingsModal from '@components/Jobfair/JobfairSettingsModal.vue';
import Button from '@components/v2/ui/Button.vue';
import Pagination from '@src/components/v2/Pagination.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    JobfairSettingsModal,
    JobfairBox,
    Pagination,
    Button,
    Icon,
  },
  data: () => ({
    isLoading: true,
    isModalOpen: false,
    profileEditSettings: {},
    type: 'resume',
    pagination: {
      page: 1,
      perPage: 12,
      total: 12,
      showBy: 'Показывать по',
    },
  }),
  computed: {
    ...mapState('jobfair', ['jobFairList', 'dictionary']),

    showPagination() {
      return this.pagination.total > 12;
    },

    isTeam() {
      return this.type === 'vacancy';
    },

    list() {
      const { roles, targets } = this.dictionary || {};
      return (
        this.jobFairList?.map((profile) => ({
          ...profile,
          contacts: profile.contacts.map((contact) => ({
            ...contact,
            icon:
              { facebook: 'fb' }[contact.contactType] ||
              contact.contactType,
          })),
          target: targets?.find(({ id }) => id === profile.target.id)
            ?.label,
          roles: profile.roles.map(({ id }) =>
            roles[profile.gameId].find((role) => role.id === id),
          ),
        })) || []
      );
    },
  },

  created() {
    this.fetchJobFairDictionary().then(this.fetchJobFair);
  },

  methods: {
    ...mapActions('jobfair', [
      'fetchJobfairList',
      'fetchJobFairDictionary',
    ]),

    toggleSettingsModal(isOpen = false, params = {}) {
      this.isModalOpen = isOpen;
      this.profileEditSettings = {
        ...params,
        cardType: this.type,
      };
    },

    updateType(type) {
      this.type = type;
      this.updatePage({ page: 1 });
    },

    updatePage({ page }) {
      this.pagination.page = page;
      this.fetchJobFair();
    },

    fetchJobFair() {
      this.isLoading = true;
      this.fetchJobfairList({
        my: 1,
        type: this.type,
        search: {
          minAge: 0,
          maxAge: 100,
          page: this.pagination.page,
        },
      })
        .then((pagen) => {
          if (pagen?.totalCount) {
            this.pagination.page = pagen.pageNum;
            this.pagination.total = pagen.totalCount;
            this.pagination.perPage = pagen.pageSize;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  &-tabs {
    padding-bottom: 20px;
    @include min-laptop() {
      display: flex;
      align-items: center;
    }

    h2 {
      line-height: 1;
    }

    .button {
      vertical-align: top;
      @include min-laptop() {
        margin-left: 10px;
        margin-right: 10px;
      }
      @include max-laptop() {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }
}

.notfound {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  padding: 30px 12px;
  background-color: $dark-background;
  border-radius: 6px;

  .icon {
    display: block;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
  }
}
</style>

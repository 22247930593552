<template>
  <div class="response-log">
    <textarea
      v-model="logString"
      class="response-log__textarea"
      rows="10"
      readonly
    />
    <div class="response-log__button">
      <Button
        type="primary"
        size="regular"
        text="Копировать в буфер"
        @click="copyToClipboard(logString)"
      />
    </div>
  </div>
</template>

<script setup>
import Button from '@components/v2/ui/Button.vue';

import { computed } from 'vue'
import { useDebugStore } from '@src/shared/store/useDebugStore';
import { copyToClipboard } from '@utils/copyToClipboard';

const debugStore = useDebugStore()

const logString = computed(() => {
  return JSON.stringify(debugStore.responses);
})
</script>

<style lang="scss" scoped>
.response-log__textarea {
  width: 100%;
  resize: none;
  color: #f5f5f7;
  background: none;
  border: none;
  outline: none;
  margin-bottom: 24px;
}
.response-log__button {
  display: flex;
  justify-content: center;
}
</style>

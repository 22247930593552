<template>
  <div class="organizations-info">
    <div class="organizations-info__content">
      <div class="organizations-info__title">{{ pageTitle }}</div>
      <div class="organizations-info__text">
        {{ $t('organizations.info_descriptions') }}
      </div>
      <div class="organizations-info__list">
        <div class="organizations-info__item more">
          <div class="icon-container">
            <icon name="pvp-swords" />
          </div>
          <div class="item-content">
            <div class="item-title">
              {{ $t('organizations.info_more_title') }}
            </div>
            <div class="item-text">
              {{ $t('organizations.info_more_text') }}
            </div>
            <a
              href="https://support.vkplay.ru/pvp/Organization/2842"
              target="_blank"
              class="item-link"
            >
              {{ $t('organizations.info_more_link') }}
              <icon name="angle-right" inline />
            </a>
          </div>
        </div>
        <div class="organizations-info__item support">
          <div class="icon-container">
            <icon name="question-circle" />
          </div>
          <div class="item-content">
            <div class="item-title">
              {{ $t('organizations.info_support_title') }}
            </div>
            <div class="item-text">
              {{ $t('organizations.info_support_text') }}
            </div>
            <a
              href="https://support.vkplay.ru/pvp"
              target="_blank"
              class="item-link"
            >
              {{ $t('organizations.info_support_link') }}
              <icon name="angle-right" inline />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'OrganizationsInfo',
  components: {
    Icon,
  },
  data: () => ({
    form: {
      isOpen: false,
      isSuccess: false,
    },
  }),
  computed: {
    pageTitle() {
      return this.$t('organizations.info_title', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
  },
  methods: {
    openForm() {
      this.form.isSuccess = false;
      this.form.isOpen = true;
    },
    closeForm() {
      this.form.isOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.organizations-info {
  margin-top: 20px;
  padding: 30px 12px;
  border-radius: 6px;
  background-color: rgba($dark-two, 0.41);
  box-sizing: border-box;
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.organizations-info__content {
  @include max-tablet() {
    width: 296px;
    margin: 0 auto;
  }

  @include min-tablet() {
    width: 600px;
    margin: 0 auto;
  }

  @include min-desktop() {
    width: 800px;
    margin: 0 auto;
  }
}

.organizations-info__title {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  @include max-tablet() {
    font-size: 18px;
    line-height: 1.28;
  }
}

.organizations-info__text {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.56;
  opacity: 0.7;
  @include max-tablet() {
    margin-bottom: 20px;
  }
}

.organizations-info__item {
  padding: 30px 0;
  display: flex;
  border-top: 1px solid $dark-two;
  &.more {
    .icon-container {
      background: linear-gradient(to left, #16a7ff, #0e4396);
    }
  }
  &.cooperation {
    .icon-container {
      background: linear-gradient(to left, #f9ba4d, #f66b1b);
    }
  }
  &.support {
    .icon-container {
      background: linear-gradient(to left, #00a14e, #006129);
    }
  }
  @include max-tablet() {
    padding: 20px 0;
  }
}

.icon-container {
  width: 90px;
  height: 90px;
  margin-right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  .icon {
    max-width: 45px;
    max-height: 45px;
    width: 100%;
    height: 100%;
  }
  @include max-tablet() {
    width: 65px;
    height: 65px;
    margin-right: 10px;
    .icon {
      max-width: 26px;
      max-height: 26px;
    }
  }
}

.item-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  @include max-tablet() {
    font-size: 18px;
    line-height: 1.28;
  }
}

.item-text {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 1.38;
}

.item-link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: $dodger-blue;
  white-space: normal;
  line-height: normal;
  &.button {
    padding: 0;
    margin: 0;
    text-align: left;
    background-color: transparent;
    border: none;
    outline: none;
  }
}
</style>

import api from '@utils/api.js';

/**
 * @typedef {Object} ProfilePayoutSummary
 * @property {string} currency
 * @property {string} paidTotalAmount
 * @property {string} pendingTotalAmount
 */

/**
 * @return {ProfilePayoutSummary}
 */
export const fetchProfilePayoutSummary = async () => {
  const { data } = await api.get(`/profile/payout/summary`);
  return data;
};

/**
 * @typedef {Object} ProfilePayoutVkIdStatus
 * @property {string | null} vkId
 * @property {boolean} isPayable
 * @property {string} vkPayStatus - 'notLinked' | 'inactive' | 'ok'
 * @property {string} trustDocStatus - 'notLinked' | 'declined' | 'processing' | 'ok'
 */

/**
 * @return {ProfilePayoutVkIdStatus}
 */
export const fetchProfilePayoutVkIdStatus = async () => {
  const { data } = await api.get(`/profile/payout/vkId/status`);
  return data;
};

/**
 * @return {string}
 */
export const fetchProfilePayoutVkIdTrustDocLink = async () => {
  const { data } = await api.post(
    `/profile/payout/vkId/trustDoc/link`,
  );
  const link = data?.link ? data.link : '';
  return link;
};

/**
 * @return {Array}
 */
export const fetchProfilePayout = async () => {
  const { data } = await api.get(`/profile/payout`);
  return data;
};

export const fetchRefreshStatus = async () => {
  const url = 'profile/payout/vkId/wizard/refresh';
  return await api.post(url);
};

<template>
  <div class="team-changes">
    <loader v-if="loadingState.history" />
    <template v-else-if="historyChanges.length">
      <div class="pvp-table stripped adaptive">
        <div class="table-header">
          <div class="table-row">
            <cell-header t="date.single" />
            <cell-header t="global.action" />
          </div>
        </div>

        <div class="table-body">
          <div
            v-for="(item, index) in historyChanges"
            :key="index"
            class="table-row"
          >
            <cell-simple
              t="date.single"
              class="date"
              :value="item.changedAt"
            />
            <cell-simple t="global.action">
              <div class="changes">
                <template
                  v-for="(word, key) in item.eventPattern.split('%%')"
                >
                  <BaseLink
                    v-if="key % 2"
                    :key="key"
                    class="player-link"
                    :to="{
                      name: 'profile',
                      params: { hash: item.player.hash },
                    }"
                  >
                    <user-avatar :src="item.player.image" />
                    <div class="player-name">
                      {{ item.player.nick }}
                    </div>
                  </BaseLink>
                  <span
                    v-else-if="word"
                    :key="key"
                    class="info-text"
                    >{{ word }}</span
                  >
                </template>
              </div>
            </cell-simple>
          </div>
        </div>
      </div>
      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="loadingState.history"
        @onchange="setPage"
      />
    </template>
    <div v-else class="placeholder">
      {{ $t('teams.placeholders_changes') }}
    </div>
  </div>
</template>

<script>
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  components: {
    CellSimple,
    CellHeader,
    BaseLink,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        ...this.loadingState,
        history: true,
      },
      historyChanges: [],

      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    };
  },
  computed: {
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },
  },
  watch: {
    perPage: {
      handler: 'reloadRating',
    },
    currentPage: {
      handler: 'reloadRating',
      immediate: true,
    },
  },
  created() {
    this.loadingState.history = true;
  },
  methods: {
    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
    },

    reloadRating() {
      this.loadingState.history = true;
      this.loadChangesHistory();
    },

    loadChangesHistory: function () {
      api
        .post(`/team/${this.hash}/membership/history`, {
          itemsPerPage: this.perPage,
          pageNumber: this.currentPage,
        })
        .then((data) => {
          this.historyChanges = data.items.map((item) => ({
            ...item,
            changedAt: this.$dt(item.changedAt, 'dd_MM_YYYY_HH_mm'),
          }));

          this.total = data.totalItems;
        })
        .finally(() => {
          this.loadingState.history = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table .table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }
  }

  &-column {
    align-items: flex-start;
    @include max-laptop() {
      ::v-deep .column-title {
        width: 80px;
        flex-shrink: 0;
      }
    }
  }
}

.pagination {
  margin-top: 20px;
}

.info-text {
  margin-left: 4px;
  vertical-align: top;
}

.placeholder {
  margin-top: 20px;
  padding: 24px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background-color: rgba($dark-two, 0.41);
  font-size: 15px;
  text-align: center;
}

.player {
  &-link {
    display: inline-flex;

    &:hover {
      text-decoration: none;
    }
  }

  &-name {
    color: white;
    font-weight: bold;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include max-laptop() {
      max-width: 180px;
    }
  }
}

.user-avatar {
  width: 26px;
  height: 26px;
  margin-right: 10px;

  &.inline.icon {
    width: 26px;
    height: 26px;
  }
}
</style>

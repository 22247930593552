<template>
  <RouterLink
    class="rewards-tournament"
    :to="`/tournament/${tournamentId}`"
  >
    <h3 class="rewards-tournament__title">
      {{ tournamentName }}
    </h3>
    <game-tag :id="gameId" />
  </RouterLink>
</template>

<script>
export default {
  name: 'RewardsTournament',
  props: {
    tournamentId: {
      type: Number,
      required: true,
    },
    gameId: {
      type: Number,
      required: true,
    },
    tournamentName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards-tournament {
  display: block;
  color: #f5f5f7;
}

.rewards-tournament__title {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-bottom: 6px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 440px;
  }
}
</style>

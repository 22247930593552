<template>
  <div class="rewards-data">
    <RewardsCard title="VK ID" :subtitle="vkIdSubtitle">
      <Button
        v-if="vkId"
        tag="a"
        size="big"
        type="secondary"
        :text="$t('rewards.vkIdProfile')"
        href="https://account.vkplay.ru/"
        target="_blank"
        long
      />
    </RewardsCard>
    <RewardsCard
      :title="$t('rewards.vkPayWallet')"
      :subtitle="vkPaySubtitle"
      :is-error="isInactiveVkPay"
    >
      <Button
        v-if="isVkPay"
        tag="a"
        size="big"
        type="secondary"
        :text="$t('rewards.myWallet')"
        href="https://vk.com/miniapps_vkpay"
        target="_blank"
        long
      />
    </RewardsCard>
    <RewardsCard
      :title="$t('rewards.personalData')"
      :subtitle="trustDocSubtitle"
      :is-error="isDeclinedTrustDoc"
    >
    </RewardsCard>
  </div>
</template>

<script>
import RewardsCard from './RewardsCard.vue';
import Button from '@components/v2/ui/Button.vue';
import { fetchProfilePayoutVkIdTrustDocLink } from '../api';

export default {
  name: 'RewardsData',
  components: {
    RewardsCard,
    Button,
  },

  props: {
    vkId: {
      type: String,
      default: '',
    },
    trustDocStatus: {
      type: String,
      default: '',
    },
    vkPayStatus: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    isTrust() {
      return this.trustDocStatus === 'ok';
    },
    isDeclinedTrustDoc() {
      return this.trustDocStatus === 'declined';
    },
    isVkPay() {
      return this.vkPayStatus === 'ok';
    },
    isInactiveVkPay() {
      return this.vkPayStatus === 'inactive';
    },

    vkIdSubtitle() {
      return this.vkId || this.$t('rewards.notAdded');
    },
    trustDocSubtitle() {
      switch (this.trustDocStatus) {
        case 'ok':
          return this.$t('rewards.trustDocLinked');
        case 'processing':
          return this.$t('rewards.trustDocProcessing');
        case 'declined':
          return this.$t('rewards.trustDocDeclined');

        default:
          return this.$t('rewards.trustDocNotAdded');
      }
    },
    vkPaySubtitle() {
      switch (this.vkPayStatus) {
        case 'ok':
          return this.$t('rewards.linked');
        case 'inactive':
          return this.$t('rewards.notActive');

        default:
          return this.$t('rewards.notAdded');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards-data {
  display: grid;
  gap: 16px;
  margin-bottom: 24px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>

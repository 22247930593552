<template>
  <div class="profile-games">
    <headline class="headline centered spaced">
      <h3 slot="title">{{ $t('games.binded') }}</h3>
      <Button
        v-if="isCurrentUserProfile && gameAccounts.length"
        slot="addon"
        tag="button"
        size="regular"
        type="secondary"
        :loading="false"
        :text="$t('global.edit')"
        @click.native="goToProfileEdit"
      >
        <template #slotBefore>
          <icon name="pencil" />
        </template>
      </Button>
    </headline>

    <Table v-if="gameAccounts.length" class="games-table">
      <template #thead>
        <tr>
          <th v-for="(header, idx) in tableHeaders" :key="idx">
            {{ header }}
          </th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="game in gameAccounts" :key="game.id">
          <td>
            <game-tag :id="game.id" :full-name="true" />
          </td>
          <td>
            <div
              v-for="account in game.accountsList"
              :key="account.hash"
              class="game-nick"
            >
              {{ account.login }}
              <div v-if="account.shardName" class="account-server">
                {{ $t('global.server') }}: {{ account.shardName }}
              </div>
            </div>
          </td>
          <td style="text-align: right">
            <BaseLink
              :to="{
                name: 'ratings-game',
                params: { gameCode: game.code },
              }"
              icon-right="angle-right"
              >{{ $t('ratings.byGame') }}
            </BaseLink>
          </td>
        </tr>
      </template>
    </Table>

    <div v-else class="games-placeholder">
      <template v-if="isCurrentUserProfile">
        <div class="placeholder-title">
          {{ $t('profile.binding_games_need_current') }}
        </div>
        <div class="placeholder-action">
          <Button
            tag="button"
            size="regular"
            type="secondary"
            :loading="false"
            :text="$t('games.bind_few')"
            @click.native="goToProfileEdit"
          >
          </Button>
        </div>
      </template>
      <template v-else>
        <div class="placeholder-title">
          {{ $t('profile.binding_games_need_other') }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Table from '@src/components/v2/Table.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'Games',
  components: { Button, Table, Icon, BaseLink },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        this.$t('games.single'),
        this.$t('players.nickname_game_label'),
        '',
      ],
    };
  },
  computed: {
    ...mapGetters('users', ['getUserProfile', 'isLoggedIn']),
    ...mapGetters('profile', [
      'getProfileLinkedGamesAccounts',
      'getCurrentProfileHash',
    ]),

    profile() {
      return this.getUserProfile(this.hash);
    },

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.hash;
    },

    complex() {
      return this.profile?.complex || {};
    },

    gameAccounts() {
      let accounts = [];

      if (this.isCurrentUserProfile) {
        accounts = this.getProfileLinkedGamesAccounts;
      } else {
        accounts = this.complex?.games?.map((game) => ({
          code: game.code,
          id: game.gameId,
          accountsList: game.accountsList,
          hasAccount: game.accountsList?.length > 0,
        }));
      }

      return accounts?.filter((account) => account.hasAccount) || [];
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.fetchProfileGames();
    }
  },
  methods: {
    ...mapActions('profile', ['fetchProfileGames']),

    goToProfileEdit() {
      this.$router.push({ name: 'profile-edit', hash: '#games' });
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  margin-bottom: 18px;
}

.games-placeholder {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: $dark-background;
  border-radius: 3px;
  box-shadow: $default-box-shadow;
  padding-top: 30px;
  padding-bottom: 30px;
  @include max-tablet() {
    padding: 30px 12px;
  }

  .placeholder-title {
    font-size: 16px;
  }

  .placeholder-action {
    margin-top: 12px;
  }
}

.headline {
  margin-top: 30px;
  @include max-tablet() {
    margin-top: 6px;
  }
}

.game-nick {
  & + & {
    margin-top: 12px;
  }
}

.account-server {
  color: rgba(white, 0.5);
  font-size: 14px;
}
</style>

<template>
  <main-layout :key="loadingState.page" class="padded">
    <div v-if="profile" class="pvp-container">
      <BaseLink
        class="profile-link"
        icon-left="arrow-left"
        :to="{ name: 'my-profile' }"
      >
        {{ $t('profile.single') }}
      </BaseLink>

      <h1 class="title">{{ $t('profile.edit_single') }}</h1>
      <h2 class="subtitle">{{ $t('info.common') }}</h2>

      <div class="profile-edit-page">
        <div class="profile-content">
          <box id="main" ref="main" class="box-border common">
            <profile-edit-common />
          </box>

          <h2 class="subtitle">
            {{ $t('global.contacts') }}
            <span class="subtitle-info">
              <icon name="info" /> {{ $t('profile.info_contacts') }}
            </span>
          </h2>

          <box id="contacts" ref="contacts" class="box-border padded">
            <div class="profile-form-wrapper">
              <profile-edit-contacts>
                <h3 slot="main-title">{{ $t('global.main') }}</h3>
                <h3 slot="additional-title">
                  {{ $t('global.additionally') }}
                </h3>
              </profile-edit-contacts>
            </div>
          </box>

          <h2 class="subtitle">
            {{ $t('info.personal') }}
            <span class="subtitle-info">
              <icon name="info" /> {{ $t('profile.info_personal') }}
            </span>
          </h2>

          <box id="personal" ref="personal" class="box-border padded">
            <div class="profile-form-wrapper">
              <h3 class="box-border-title">
                {{ $t('global.main') }}
              </h3>
              <personal-form />
            </div>
          </box>

          <h2 class="subtitle">
            {{ $t('profile.fcs_shortTitle') }}
          </h2>

          <box id="fcs" ref="fcs" class="box-border padded">
            <div class="profile-form-wrapper">
              <fcs-account-linker
                :is-fks-pd-completed="isFksPdCompleted"
              />
            </div>
          </box>

          <h2 class="subtitle">
            {{ $t('games.binded') }}
          </h2>

          <games-list-table id="games" ref="games" />
        </div>

        <div class="profile-navigation">
          <nav class="navigation-list">
            <a
              v-for="(title, key) in navigation"
              :key="key"
              :href="`#${key}`"
              :class="{ active: currentNavKey === key }"
              class="navigation-list__item"
              @click="setCurrentNavKey(key)"
            >
              {{ title }}
            </a>
          </nav>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import FcsAccountLinker from '@components/ProfileComponents/FcsAccountLinker.vue';
import PersonalForm from '@components/ProfileComponents/ProfileEdit/PersonalForm.vue';
import GamesListTable from '@components/ProfileComponents/ProfileEdit/GamesListTable.vue';
import ProfileEditCommon from '@components/ProfileComponents/ProfileEdit/ProfileEditCommon.vue';
import ProfileEditContacts from '@components/ProfileComponents/ProfileEdit/ProfileEditContacts.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'Edit',
  components: {
    Icon,
    ProfileEditContacts,
    ProfileEditCommon,
    GamesListTable,
    PersonalForm,
    FcsAccountLinker,
    BaseLink,
  },
  page() {
    return {
      title: this.$t('profile.management'),
    };
  },
  data() {
    return {
      newTeamModalOpened: false,

      navigation: {
        main: this.$t('info.common'),
        contacts: this.$t('global.contacts'),
        personal: this.$t('info.personal'),
        fcs: this.$t('profile.fcs_shortTitle'),
        games: this.$t('games.binded'),
      },
      currentNavKey: 'main',
    };
  },
  computed: {
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('profile', [
      'getCurrentProfile',
      'getCurrentProfileHash',
      'getCurrentProfileDetails',
    ]),

    profile() {
      return this.getCurrentProfile;
    },

    teams() {
      return this.getUserTeams(this.getCurrentProfileHash);
    },

    tabs() {
      return [];
    },

    currentTab() {
      return this.tabs[this.currentTabIndex];
    },

    isFksPdCompleted() {
      return this.getCurrentProfileDetails?.fcs?.isFksPdCompleted;
    },
  },
  mounted() {
    this.loadingState.page = true;
    let hash = this.$route.hash.slice(1);
    this.setCurrentNavKey(hash);

    this.$store
      .dispatch('users/fetchUserProfile', {
        hash: this.getCurrentProfileHash,
        complex: true,
      })
      .finally(() => {
        this.loadingState.page = false;
      });
  },
  methods: {
    setCurrentNavKey(key) {
      this.currentNavKey = key;
    },
    showNewTeamModal() {
      this.newTeamModalOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-link {
  font-size: 17px;
}

.title {
  margin-top: 24px;

  @include max-tablet() {
    margin-top: 12px;
  }
}

.subtitle {
  margin-top: 40px;
  margin-bottom: 24px;
  @include min-desktop() {
    display: inline-flex;
    align-items: center;
  }
  @include max-tablet() {
    margin-top: 30px;
  }

  &-info {
    color: rgba(white, 0.6);
    font-size: 15px;
    font-weight: 400;
    display: flex;
    @include min-desktop() {
      margin-left: 1em;
      align-items: center;
    }
    @include max-desktop() {
      margin-top: 0.5em;
    }

    .icon {
      font-size: 20px;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}

.profile-edit-page {
  display: flex;
  gap: 32px;
  justify-content: space-between;
}

.profile-content {
  flex-grow: 1;
}

.box-border {
  padding: 30px;
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
    padding: 30px 12px 24;
  }

  &.padded {
    @include min-tablet() {
      padding-left: 190px;
    }
  }

  &-title {
    margin-bottom: 24px;
  }

  .profile-form-wrapper {
    @include min-tablet() {
      width: 400px;
    }
  }
}

.profile-navigation {
  flex-shrink: 0;
  width: 380px;
  margin-left: 24;

  @include max-laptop() {
    display: none;
  }

  .navigation-list {
    top: $headerHeight + 24;
    background: $dark-two;
    padding: 14px;
    position: sticky;
    top: 24px;
    left: 0;
    border: 1px solid rgba(245, 245, 247, 0.12);
    border-radius: 8px;

    &__item {
      display: block;
      padding: 9px 14px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      color: inherit;

      &:hover {
        background-color: rgba(white, 0.05);
      }

      &.active {
        background-color: rgba(white, 0.1);
      }
    }
  }
}
</style>

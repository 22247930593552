<template>
  <div
    class="rewards-card"
    :class="{ 'rewards-card--error': isError }"
  >
    <div class="rewards-card__wrapper">
      <div class="rewards-card__content">
        <div>
          <h3 class="rewards-card__title">{{ title }}</h3>
          <p class="rewards-card__subtitle">{{ subtitle }}</p>
        </div>
        <div class="rewards-card__slot">
          <slot />
        </div>
      </div>
      <p v-if="warning" class="rewards-card__warning">
        <span class="rewards-card__warning-icon">
          <Icon name="triangle-warning" :size="16" />
        </span>
        <span class="rewards-card__warning-text">
          {{ warning }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'RewardsCard',
  components: {
    Icon,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards-card {
  border-radius: 8px;

  &--error {
    isolation: isolate;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      inset: -2px;
      background: #c64040;
      border-radius: 8px;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      inset: -2px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
      );
      border-radius: 8px;
    }
  }
}
.rewards-card__wrapper {
  display: grid;
  gap: 24px;
  padding: 16px;
  border-radius: inherit;
  background: #1d1d1e;
  height: 100%;
}
.rewards-card__content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
}
.rewards-card__title {
  color: #979797;
  margin-bottom: 6px;
}
.rewards-card__subtitle {
  color: #fff;
}
.rewards-card__title,
.rewards-card__subtitle {
  font-size: 18px;
  font-weight: 600;
}
.rewards-card__slot {
  @media screen and (min-width: 1200px) {
    margin-left: auto;
  }
}
.rewards-card__warning {
  display: flex;
  gap: 8px;
}
.rewards-card__warning-icon {
  color: #ffed47;
}
.rewards-card__warning-text {
  color: #f5f5f7;
  font-size: 14px;

  @media screen and (min-width: 1200px) {
    max-width: 260px;
  }
}
</style>

<template>
  <div class="team-rating">
    <loader v-if="isLoading" />
    <template v-else-if="ratings.length">
      <ratings-list-table :list="ratings" :need-team-info="false" />
      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="isLoading"
        @onchange="setPage"
      />
    </template>
    <div v-else class="empty">
      {{ $t('teams.placeholders_ratings') }}
    </div>
  </div>
</template>

<script>
import RatingsListTable from '@components/RatingComponents/RatingsListTable.vue';

export default {
  components: {
    RatingsListTable,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      ratings: [],
      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    };
  },
  computed: {
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > this.perPageSizes?.[0];
    },
  },
  created() {
    this.reloadRating();
  },
  methods: {
    ...mapActions('teams', ['fetchTeamRatings']),

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
      this.reloadRating();
    },

    reloadRating() {
      this.isLoading = true;
      this.fetchTeamRatings({
        page: this.currentPage,
        limit: this.perPage,
        hash: this.hash,
      })
        .then((data) => {
          this.ratings = data.rating;
          this.total = data.pagination.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  margin-top: 20px;
}

.empty {
  margin-top: 20px;
  padding: 24px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background-color: rgba($dark-two, 0.41);
  font-size: 15px;
  text-align: center;
}
</style>

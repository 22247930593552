var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-organizations"},[_c('headline',{staticClass:"centered"},[_c('h3',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('organizations.my'))+" "),_c('pvp-tooltip',{staticClass:"information-icon"},[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('organizations.tooltip'))+" ")])],2)],1),(_vm.organizations.length)?_c('Button',{attrs:{"slot":"addon","tag":"button","size":"regular","type":"secondary","loading":false,"text":_vm.$t('organizations.create_title')},nativeOn:{"click":function($event){return _vm.changeOrganization({ variant: 'create' })}},slot:"addon",scopedSlots:_vm._u([{key:"slotBefore",fn:function(){return [_c('Icon',{attrs:{"name":"plus","inline":""}})]},proxy:true}],null,false,3548742768)}):_vm._e()],1),(_vm.loadingState.page)?[_c('div',{staticClass:"loader-container"},[_c('loader')],1)]:[(_vm.organizations.length)?[_c('Table',{scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('organizations.few')))]),_c('th',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.$t('players.role_current'))+" ")]),_c('th')])]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.organizations),function(organization){return _c('tr',{key:organization.id},[_c('td',{staticClass:"table-column organization"},[_c('OrganizationLink',{attrs:{"id":organization.id,"display-image":!_vm.appIsMobile}})],1),_c('td',{staticClass:"table-column member-type",staticStyle:{"text-align":"left"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(`players.role_${organization.role}`))+" ")])]),_c('td',{staticClass:"table-column controls",staticStyle:{"text-align":"right"}},[(organization.canEdit)?_c('Button',{attrs:{"tag":"button","size":"regular","type":"secondary","loading":false,"text":_vm.$t('global.edit')},nativeOn:{"click":function($event){return _vm.changeOrganization({
                    id: organization.id,
                    variant: 'edit',
                  })}}}):(organization.canLeave)?_c('Button',{attrs:{"tag":"button","size":"regular","type":"secondary","loading":false,"text":_vm.$t('global.leave')},nativeOn:{"click":function($event){return _vm.changeOrganization({
                    id: organization.id,
                    variant: 'leave',
                  })}}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('teams.cantLeave_text'))+" "),_c('pvp-tooltip',[_c('Icon',{attrs:{"name":"info","inline":""}}),_c('template',{slot:"tooltip"},[_vm._v(" "+_vm._s(_vm.$t('teams.cantLeave_tooltip'))+" ")])],2)],1)],1)])})},proxy:true}],null,false,4080937565)}),(_vm.showPagination)?_c('Pagination',{attrs:{"params":_vm.paginationParams},on:{"onchange":_vm.setPage}}):_vm._e()]:[_c('div',{staticClass:"teams-placeholder"},[_c('div',{staticClass:"placeholder-title"},[_vm._v(" "+_vm._s(_vm.$t('organizations.create_first'))+" ")]),_c('div',{staticClass:"placeholder-action"},[_c('Button',{attrs:{"tag":"button","size":"regular","type":"secondary","loading":false,"text":_vm.$t('organizations.create_title')},nativeOn:{"click":function($event){return _vm.changeOrganization({ variant: 'create' })}},scopedSlots:_vm._u([{key:"slotBefore",fn:function(){return [_c('Icon',{attrs:{"name":"plus"}})]},proxy:true}])})],1)])],_c('organizations-info')],_c('organization-settings',{attrs:{"id":_vm.organizationModal.id,"variant":_vm.organizationModal.variant,"hash":_vm.organizationModal.hash},on:{"success":_vm.loadOrganizations},model:{value:(_vm.organizationModal.isOpen),callback:function ($$v) {_vm.$set(_vm.organizationModal, "isOpen", $$v)},expression:"organizationModal.isOpen"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <pvp-modal v-bind="$attrs" :width="580" v-on="$listeners">
    <div slot="modal-title">{{ $t('teams.leave_title') }}</div>
    <InfoBox v-if="leaveError" variant="warning">
      {{ $t('teams.leave_warning') }}
    </InfoBox>
    <template v-else>
      <div class="leave-info-text">
        {{ $t('teams.leave_leaveTeam', { name: teamName }) }}?
      </div>
      <template slot="modal-footer">
        <pvp-btn
          class="leave-modal-btn"
          variant="secondary"
          @click="closeLeaveTeamModal"
        >
          {{ $t('global.cancel') }}
        </pvp-btn>

        <pvp-btn
          class="leave-modal-btn"
          variant="primary"
          :disabled="loadingState.update"
          :is-loading="loadingState.update"
          @click="leaveTeamHandler"
        >
          {{ $t('global.confirm') }}
        </pvp-btn>
      </template>
    </template>
  </pvp-modal>
</template>

<script>
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import { leaveTeam } from '@src/shared/api/team';

export default {
  name: 'LeaveTeamModal',
  components: { InfoBox },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      update: false,
    },
    leaveError: false,
  }),
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),

    teamName() {
      return this.team.name;
    },

    userHash() {
      return this.getCurrentProfileHash;
    },
  },
  methods: {
    closeLeaveTeamModal() {
      this.$emit('input', false);
    },
    async leaveTeamHandler() {
      this.loadingState.update = true;
      try {
        const { success } = await leaveTeam(this.team.hash);
        if (success) {
          this.$emit('leaveTeam', true);
          this.closeLeaveTeamModal();
        }
      } catch (err) {
        this.leaveError = true;
      } finally {
        this.loadingState.update = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.leave-info-text {
  font-size: 16px;
  text-align: center;
}

.leave-modal-btn {
  width: 160px;

  & + .leave-modal-btn {
    margin-left: 12px;
  }

  @include max-tablet() {
    width: 100%;
    & + .leave-modal-btn {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}
</style>

<template>
  <div class="profile-organizations">
    <headline class="centered">
      <h3 slot="title">
        {{ $t('organizations.my') }}
        <pvp-tooltip class="information-icon">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('organizations.tooltip') }}
          </template>
        </pvp-tooltip>
      </h3>
      <Button
        v-if="organizations.length"
        slot="addon"
        tag="button"
        size="regular"
        type="secondary"
        :loading="false"
        :text="$t('organizations.create_title')"
        @click.native="changeOrganization({ variant: 'create' })"
      >
        <template #slotBefore>
          <Icon name="plus" inline />
        </template>
      </Button>
    </headline>
    <template v-if="loadingState.page">
      <div class="loader-container">
        <loader />
      </div>
    </template>
    <template v-else>
      <template v-if="organizations.length">
        <Table>
          <template #thead>
            <tr>
              <th>{{ $t('organizations.few') }}</th>
              <th style="text-align: left">
                {{ $t('players.role_current') }}
              </th>
              <th></th>
            </tr>
          </template>
          <template #tbody>
            <tr
              v-for="organization in organizations"
              :key="organization.id"
            >
              <td class="table-column organization">
                <OrganizationLink
                  :id="organization.id"
                  :display-image="!appIsMobile"
                />
              </td>
              <td
                style="text-align: left"
                class="table-column member-type"
              >
                <div>
                  {{ $t(`players.role_${organization.role}`) }}
                </div>
              </td>
              <td
                style="text-align: right"
                class="table-column controls"
              >
                <Button
                  v-if="organization.canEdit"
                  tag="button"
                  size="regular"
                  type="secondary"
                  :loading="false"
                  :text="$t('global.edit')"
                  @click.native="
                    changeOrganization({
                      id: organization.id,
                      variant: 'edit',
                    })
                  "
                >
                </Button>
                <Button
                  v-else-if="organization.canLeave"
                  tag="button"
                  size="regular"
                  type="secondary"
                  :loading="false"
                  :text="$t('global.leave')"
                  @click.native="
                    changeOrganization({
                      id: organization.id,
                      variant: 'leave',
                    })
                  "
                >
                </Button>
                <span v-else>
                  {{ $t('teams.cantLeave_text') }}
                  <pvp-tooltip>
                    <Icon name="info" inline />
                    <template slot="tooltip">
                      {{ $t('teams.cantLeave_tooltip') }}
                    </template>
                  </pvp-tooltip>
                </span>
              </td>
            </tr>
          </template>
        </Table>

        <Pagination
          v-if="showPagination"
          :params="paginationParams"
          @onchange="setPage"
        />
      </template>
      <template v-else>
        <div class="teams-placeholder">
          <div class="placeholder-title">
            {{ $t('organizations.create_first') }}
          </div>
          <div class="placeholder-action">
            <Button
              tag="button"
              size="regular"
              type="secondary"
              :loading="false"
              :text="$t('organizations.create_title')"
              @click.native="
                changeOrganization({ variant: 'create' })
              "
            >
              <template #slotBefore>
                <Icon name="plus" />
              </template>
            </Button>
          </div>
        </div>
      </template>
      <organizations-info />
    </template>

    <organization-settings
      :id="organizationModal.id"
      v-model="organizationModal.isOpen"
      :variant="organizationModal.variant"
      :hash="organizationModal.hash"
      @success="loadOrganizations"
    />
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import OrganizationSettings from '@components/OrganizationComponents/OrganizationSettings.vue';
import OrganizationsInfo from '@components/OrganizationComponents/OrganizationsInfo.vue';
import Pagination from '@src/components/v2/Pagination.vue';
import Table from '@src/components/v2/Table.vue';
import Icon from '@components/v2/utils/Icon.vue';
import OrganizationLink from '@components/BaseComponents/OrganizationLink.vue';

export default {
  name: 'Organizations',
  components: {
    OrganizationSettings,
    OrganizationsInfo,
    OrganizationLink,
    Table,
    Button,
    Pagination,
    Icon,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    organizationModal: {
      variant: '',
      id: null,
      isOpen: false,
      hash: null,
    },

    organizationsIds: [],

    // Pagination
    currentPage: 1,
    perPage: 12,
    total: 0,
    perPageSizes: [12, 36, 72],
  }),

  computed: {
    ...mapGetters('users', ['getUserProfile']),
    ...mapGetters('application', ['appIsMobile']),
    ...mapGetters('organizations', ['getOrganization']),

    profile() {
      return this.getUserProfile(this.hash);
    },

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
        showBy: 'Показывать по',
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },

    organizations() {
      return this.organizationsIds.map(this.getOrganization);
    },
  },

  created() {
    this.loadOrganizations();
  },
  methods: {
    ...mapActions('organizations', [
      'fetchProfileOrganizations',
      'fetchOrganization',
    ]),
    async changeOrganization({ id = 0, variant = '' }) {
      if (variant === 'edit') {
        await this.fetchOrganization(id);
      }

      this.organizationModal.id = id;
      this.organizationModal.hash = this.hash;
      this.organizationModal.variant = variant;
      this.organizationModal.isOpen = true;
    },

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
      this.loadOrganizations();
    },

    loadOrganizations() {
      this.loadingState.page = true;
      return this.fetchProfileOrganizations({
        page: this.currentPage,
        limit: this.perPage,
      })
        .then((data) => {
          this.organizationsIds = data.organizations.map(
            (organization) => organization.id,
          );
          this.total = data.pagination.total;
        })
        .finally(() => {
          this.loadingState.page = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-organizations {
  .teams-placeholder {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $dark-background;
    border-radius: 3px;
    box-shadow: $default-box-shadow;
    padding-top: 30px;
    padding-bottom: 30px;
    @include max-tablet() {
      padding: 30px 12px;
    }

    .placeholder-title {
      font-size: 16px;
    }

    .placeholder-action {
      margin-top: 12px;
    }
  }

  .organizations-list-table {
    margin-top: 18px;
  }

  .information-icon {
    color: rgba(white, 0.5);
    font-size: 20px;
    line-height: 20px;
    margin-top: -4px;
    margin-left: 10px;
  }

  .loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pvp-pagination {
    margin-top: 30px;
  }
}

.headline {
  margin-top: 30px;
  margin-bottom: 18px;
  @include max-tablet() {
    margin-top: 6px;
  }
}
</style>

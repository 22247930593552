<template>
  <div class="team-rating">
    <loader v-if="isLoading" />
    <template v-else-if="tournaments.length">
      <div class="pvp-table stripped adaptive">
        <div class="table-header">
          <div class="table-row">
            <cell-header t="date.single" />
            <cell-header t="tournaments.single" />
            <cell-header t="global.result" />
            <cell-header />
          </div>
        </div>

        <div class="table-body">
          <div
            v-for="(item, index) in tournaments"
            :key="index"
            class="table-row"
          >
            <cell-simple class="tournament">
              <game-tag :id="item.idGame" />
              <BaseLink
                :to="{
                  name: 'tournament',
                  params: { id: item.urlCode || item.id },
                }"
              >
                {{ item.name }}
              </BaseLink>
            </cell-simple>
            <cell-date
              :ts="item.tsStartRun"
              class="date"
              format="dd_MM_YYYY_HH_mm"
            />
            <cell-simple t="global.result" class="result">
              <!-- eslint-disable vue/no-v-html -->
              <div v-html="item.statusName" />
            </cell-simple>
            <cell-simple class="more">
              <BaseLink
                class="table-link"
                :to="{
                  name: 'tournament',
                  params: { id: item.urlCode || item.id },
                }"
                icon-right="angle-right"
              >
                {{ $t('global.more') }}
              </BaseLink>
            </cell-simple>
          </div>
        </div>
      </div>

      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="isLoading"
        @onchange="setPage"
      />
    </template>
    <div v-else class="tournaments-placeholder">
      {{ $t('teams.placeholders_tournaments') }}
    </div>
  </div>
</template>

<script>
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellDate from '@components/TableCells/CellDate.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  components: {
    CellSimple,
    CellDate,
    CellHeader,
    BaseLink,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      tournaments: [],
      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    };
  },
  computed: {
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },
  },
  watch: {
    perPage: {
      handler: 'reloadRating',
    },
    currentPage: {
      handler: 'reloadRating',
      immediate: true,
    },
  },
  methods: {
    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
    },

    reloadRating() {
      this.isLoading = true;
      return this.loadTeamRating();
    },

    loadTeamRating: function () {
      api
        .get(`/team/${this.hash}/tournaments`, {
          params: {
            pageSize: this.perPage,
            pageNum: this.currentPage - 1,
          },
        })
        .then((data) => {
          this.tournaments = data.items;
          this.total = data.pagen.totalCount;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table .table-body {
  .table-column {
    padding-right: 12px;

    &.tournament {
      @include min-laptop() {
        padding-left: 0;
      }
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 2fr 2fr 1fr;
    }
  }

  &-column {
    &.tournament {
      @include min-laptop() {
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        order: 1;
        padding-left: 0;
      }

      .base-link {
        display: block;
        width: 100%;
        color: white;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
          text-decoration: none;
        }

        @include min-laptop() {
          order: 0;
        }
      }

      .game-tag {
        @include min-laptop() {
          order: 1;
        }
        @include max-laptop() {
          margin-right: 6px;
          flex-shrink: 0;
          ::v-deep .game-name {
            display: none;
          }
        }
      }
    }

    &.date {
      @include min-laptop() {
        padding-left: 30px;
        order: 0;
      }
      @include max-laptop() {
        ::v-deep .column-title {
          width: 80px;
        }
      }
    }

    &.result {
      overflow: hidden;
      @include min-laptop() {
        display: block;
        order: 2;
      }
      @include max-laptop() {
        align-items: flex-start;
        ::v-deep .column-title {
          width: 80px;
        }
      }
    }

    &.more {
      @include min-laptop() {
        justify-content: flex-end;
        order: 3;
      }
    }
  }
}

.pagination {
  margin-top: 20px;
}

.tournaments-placeholder {
  margin-top: 20px;
  padding: 24px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;
  background-color: rgba($dark-two, 0.41);
  font-size: 15px;
  text-align: center;
}
</style>

<template>
  <div class="profile-agreements">
    <loader v-if="isLoading" class="pvp-loader" />
    <template v-else>
      <headline>
        <h3 slot="title">
          {{ $t('terms.few') }}
        </h3>
      </headline>
      <div v-if="terms.length" class="pvp-table">
        <div class="table-body">
          <div v-for="term in terms" :key="term.id" class="table-row">
            <div class="table-column">{{ term.name }}</div>
            <div class="table-column controls">
              <pvp-btn
                variant="link"
                icon-right="angle-right"
                @click="
                  termStore.openTermModal({
                    code: term.code,
                    version: term.idVersion,
                  })
                "
              >
                <span class="text">{{ $t('global.more') }}</span>
              </pvp-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
    <pvp-pagination
      v-if="isPagination"
      :disabled="isLoading"
      :params="pagination"
      @onchange="fetchTerms"
    />
  </div>
</template>

<script>
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'ProfileTerms',
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  setup() {
    const termStore = useTermStore();
    return { termStore };
  },
  data() {
    return {
      isLoading: false,
      terms: [],
      pagination: {
        page: 1,
        perPage: 12,
        total: 1,
      },
    };
  },
  computed: {
    isPagination() {
      return this.pagination.total >= 12;
    },
  },
  created() {
    this.fetchTerms(this.pagination);
  },
  methods: {
    ...mapActions('profile', ['fetchProfileTerms']),
    fetchTerms({ page: pageNum, perPage: pageSize }) {
      this.isLoading = true;
      this.fetchProfileTerms({
        pageSize,
        pageNum: pageNum - 1,
      })
        .then(({ items, pagen }) => {
          this.terms = items || [];
          this.pagination = {
            page: pagen.pageNum + 1,
            perPage: pagen.pageSize,
            total: pagen.totalCount,
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table {
  border-radius: 6px;
  border: none;

  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .table {
    &-row {
      display: grid;
      grid-template-columns: 5fr 1fr;
    }

    &-body {
      border-radius: inherit;

      @include max-laptop() {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}

.controls {
  justify-content: flex-end;

  .button {
    @include max-laptop() {
      font-size: 24px;
    }
  }
}

.headline {
  margin-top: 30px;
  margin-bottom: 18px;
  @include max-tablet() {
    margin-top: 6px;
  }
}

.text {
  @include max-laptop() {
    display: none;
  }
}
</style>

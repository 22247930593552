<template>
  <main-layout :is-loading="loadingState.page">
    <div v-if="profile" class="profile-page">
      <div class="pvp-container">
        <div class="profile-headline">
          <headline class="centered">
            <h1 slot="title">{{ $t('profile.single') }}</h1>
            <Button
              v-if="isCurrentUserProfile"
              slot="addon"
              tag="button"
              size="regular"
              type="secondary"
              :loading="false"
              :text="$t('global.edit')"
              @click.native="goToProfileEdit"
            >
              <template #slotBefore>
                <icon name="settings" />
              </template>
            </Button>
          </headline>
          <BaseSocialLinks
            class="profile-socials"
            :show-title="false"
            :share-title="shareTitle"
            :share-img="profile.avatarUrl || ''"
          />
        </div>
        <div class="profile-header">
          <div
            class="header-column avatar"
            @mousedown.left="enableDebugModal"
            @mouseup="disableDebugModal"
            @touchstart.prevent="enableDebugModal"
            @touchend.prevent="disableDebugModal"
          >
            <user-avatar :src="profile.image" />
            <div class="user-nick">{{ profile.name }}</div>
            <div v-if="isCybersportsman" class="user-icon">
              <pvp-tooltip class="cyber-icon-tooltip">
                <Icon
                  name="cybersportsman"
                  :size="30"
                  group="color"
                />
                <template slot="tooltip">{{
                  $t('profile.is_cybersportsman_icon')
                }}</template>
              </pvp-tooltip>
            </div>
          </div>
          <div class="header-column info">
            <div class="user-info">
              <div class="user-nick">{{ profile.name }}</div>
              <div v-if="isCybersportsman" class="user-icon">
                <pvp-tooltip class="cyber-icon-tooltip">
                  <Icon
                    name="cybersportsman"
                    :size="30"
                    group="color"
                  />
                  <template slot="tooltip">{{
                    $t('profile.is_cybersportsman_icon')
                  }}</template>
                </pvp-tooltip>
              </div>
            </div>

            <div class="profile-info-list">
              <div v-if="mainTeam" class="info-list-item">
                <div class="key">{{ $t('teams.primary') }}:</div>
                <div class="value">{{ mainTeam.teamName }}</div>
              </div>
              <div v-if="countryAndCity" class="info-list-item">
                <div class="key">
                  {{ $t('profile.page_location') }}
                </div>
                <div class="value">{{ countryAndCity }}</div>
              </div>
              <div v-if="profile.age" class="info-list-item">
                <div class="key">{{ $t('global.age') }}:</div>
                <div class="value">{{ profile.age }}</div>
              </div>
            </div>
          </div>
          <template v-if="finishedTournaments">
            <div v-if="stats" class="header-column stats matches">
              <div class="stats-title">
                {{ $tc('matches.played', stats.totalMatchesCount) }}
              </div>
              <profile-match-progress :stats="stats" />
            </div>
            <div class="header-column stats tournaments">
              <div class="stats-title">
                {{ $t('tournaments.last') }}:
              </div>
              <div class="stats-body">
                <div class="tournaments-list">
                  <div
                    v-for="tournament in finishedTournaments"
                    :key="tournament.id"
                    class="tournaments-list-item"
                  >
                    <BaseLink
                      :to="{
                        name: 'tournament',
                        params: {
                          id: tournament.urlCode || tournament.id,
                        },
                      }"
                      :title="tournament.name"
                      class="tournament-link"
                      >{{ tournament.name }}
                    </BaseLink>
                    <span v-if="tournament.place" class="place"
                      ><b>{{ tournament.place }}</b>
                      {{ $t('places.single') }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="header-column stats placeholder">
              <div class="placeholder-icon">
                <icon name="pvp-swords"></icon>
              </div>
              <template v-if="isCurrentUserProfile">
                <div class="placeholder-text">
                  {{ $t('tournaments.firstTournament') }}
                </div>
                <div class="placeholder-action">
                  <Button
                    slot="addon"
                    tag="button"
                    size="regular"
                    type="secondary"
                    :loading="false"
                    :text="$t('tournaments.to_few')"
                    @click.native="goToTournaments"
                  />
                </div>
              </template>
              <template v-else>
                <div class="placeholder-text">
                  {{ $t('players.no-tournaments') }}
                </div>
              </template>
            </div>
          </template>
        </div>

        <TabList
          :key="startTabIndex"
          :tabs="tabs"
          :start-tab-index="startTabIndex"
          @change-tab="setActiveTabType"
        />

        <section class="page-content-section">
          <router-view :hash="profileHash" />
        </section>
      </div>
    </div>

    <Modal
      v-if="isShowDedugModal"
      title="Log"
      type="regular"
      @close="handleCloseModal"
    >
      <ResponseLog />
    </Modal>
  </main-layout>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import ProfileMatchProgress from '@components/ProfileComponents/ProfileMatchProgress.vue';
import TabList from '@src/components/v2/TabList.vue';
import Button from '@components/v2/ui/Button.vue';
import Modal from '@components/v2/Modal.vue';
import ResponseLog from '@src/shared/debug/responseLog';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'Profile',
  components: {
    ProfileMatchProgress,
    TabList,
    Button,
    Modal,
    Icon,
    ResponseLog,
    BaseSocialLinks,
    BaseLink,
  },
  page() {
    return {
      title: this.title,
    };
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShowDedugModal: false,
      counter: 0,
      intervalID: null,
    };
  },
  computed: {
    ...mapGetters('users', ['getUserProfile']),
    ...mapGetters('application', ['getCountry']),
    ...mapGetters('profile', [
      'getCurrentProfileHash',
      'getCurrentProfile',
    ]),

    profile() {
      return this.getUserProfile(this.profileHash);
    },

    profileHash() {
      return this.hash || this.getCurrentProfileHash;
    },

    stats() {
      return this.profile?.matchesStatistic;
    },

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.profileHash;
    },

    startTabIndex() {
      return (
        this.tabs.findIndex(
          (e) => e.link.name === this.$route.name,
        ) || 0
      );
    },

    tabs() {
      const path = [];

      let profileTabs = [
        {
          title: this.$t('tournaments.few'),
          link: 'profile',
        },
        {
          title: this.$t('teams.few'),
          link: 'profile-teams',
        },
        {
          title: this.$t('games.few'),
          link: 'profile-games',
        },
        {
          title: this.$t('jobfair.profile_few'),
          link: 'profile-jobfair',
          currentProfile: true,
        },
        {
          title: this.$t('ratings.few'),
          link: 'profile-ratings',
        },
        {
          title: this.$t('matches.few'),
          link: 'profile-matches',
        },
        {
          title: this.$t('rewards.rewardTabTitle'),
          link: 'profile-rewards',
          currentProfile: true,
        },
        {
          title: this.$t('terms.few'),
          link: 'profile-terms',
          currentProfile: true,
        },
        {
          title: this.$t('organizations.few'),
          link: 'profile-organizations',
          currentProfile: true,
        },
      ];

      if (!this.isCurrentUserProfile) {
        profileTabs = profileTabs.filter(
          (tab) => !tab.currentProfile,
        );
      }

      return profileTabs.map((tab) => ({
        ...tab,
        link: {
          name: [...path, tab.link].join('-'),
          params: { hash: this.profileHash },
        },
      }));
    },

    complex() {
      return _.get(this.profile, 'complex', {});
    },

    finishedTournaments() {
      const tournaments = _.get(
        this.profile,
        'lastTournamentsStatistic',
        null,
      );

      if (!tournaments || !tournaments.length) {
        return null;
      }

      return tournaments.slice(0, 3);
    },

    mainTeam() {
      return _.head(_.get(this.complex, 'teams_current', []));
    },

    country() {
      return this.getCountry(this.profile.idCountry);
    },

    countryAndCity() {
      const countryAndCity = [];

      if (this.country) {
        countryAndCity.push(this.country.name);
      }

      if (this.profile && this.profile.city) {
        countryAndCity.push(this.profile.city);
      }

      return countryAndCity.length ? countryAndCity.join(', ') : null;
    },

    currentTab() {
      return this.tabs[this.currentTabIndex];
    },

    shareTitle() {
      return this.isCurrentUserProfile
        ? this.$t('profile.shareTitle_current')
        : this.$t('profile.shareTitle_other', {
            host: window.location.host.toLocaleUpperCase(),
          });
    },

    title() {
      if (this.isCurrentUserProfile) {
        return this.$t('profile.my');
      }

      const nick = this.profile ? this.profile.nick : '';

      return `${this.$t('profile.single')} ${nick}`;
    },

    isCybersportsman() {
      return this.profile?.isCybersportsman;
    },
  },
  watch: {
    hash: {
      handler: 'fetchData',
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      this.loadingState.page = true;

      this.$store
        .dispatch('users/fetchUserProfile', {
          hash: this.profileHash,
          complex: true,
        })
        .then(() => {
          this.loadingState.page = false;
        })
        .catch(() => {
          this.$router.push({
            name: 'not-found',
          });
        });
    },

    tabInputHadler(index, title) {
      // ======================================================
      // METRICS Турниры. Клик по элементу в профиле
      const tmrParams = {
        category: 'pvp',
        action: 'profile_click',
        label: title,
        url: window.location.href,
      };
      pushEvents('pvp_profile_click', tmrParams);
      // ======================================================
    },

    setActiveTabType(data) {
      const {
        currentTab: { link },
      } = data;
      this.$router.push({ ...link, query: this.$route.query });
    },

    goToProfileEdit() {
      this.$router.push({ name: 'profile-edit' });
    },

    goToTournaments() {
      this.$router.push({ name: 'tournaments' });
    },

    enableDebugModal() {
      this.intervalID = setInterval(() => {
        this.counter += 1;
        if (this.counter === 5) {
          this.isShowDedugModal = true;
          this.disableDebugModal();
        }
      }, 1000);
    },
    disableDebugModal() {
      this.counter = 0;
      clearInterval(this.intervalID);
    },
    handleCloseModal() {
      this.isShowDedugModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-page {
  padding-top: 40px;

  .profile-header {
    margin-top: 26px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.11);
    display: flex;
    background-color: $dark-two;
    padding: 20px 30px;
    border-radius: 6px;
    margin-bottom: 8px;

    .header-column {
      font-family: 'VK Sans Display';
      &.avatar {
        align-self: center;

        .user-avatar {
          width: 130px;
          height: 130px;
        }

        .user-nick {
          display: none;
        }

        .user-icon {
          display: none;
        }
      }

      &.info {
        align-self: center;
        margin-left: 30px;

        .user-nick {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
        }

        .profile-info-list {
          font-size: 13px;

          .info-list-item {
            display: flex;
            align-items: flex-start;
            line-height: 1;
            margin-bottom: 14px;

            .key {
              width: 130px;
              color: rgba(white, 0.5);
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
            }

            .value {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: var(--main-color-white);
            }
          }
        }
      }

      &.stats {
        &.placeholder {
          width: 50%;
          margin-left: auto;
          border-left: 1px solid rgba(white, 0.1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @include max-laptop() {
            width: 100%;
          }

          .placeholder-icon {
            svg {
              width: 30px;
              height: 30px;
            }
          }

          .placeholder-text {
            text-align: center;
            margin-top: 12px;
          }

          .placeholder-action {
            margin-top: 12px;
          }

          @include max-tablet() {
            width: auto;
          }
        }

        &.matches {
          margin-left: auto;
        }

        &.tournaments {
          width: 300px;
          border-left: 1px solid rgba(white, 0.1);
          padding-left: 26px;
          margin-left: 26px;

          .tournaments-list {
            .tournaments-list-item {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              display: flex;
              align-items: flex-start;
              margin-bottom: 12px;

              .tournament-link {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--additional-color-blue);
              }

              .place {
                white-space: nowrap;

                &:before {
                  content: '—';
                  margin-left: 6px;
                  margin-right: 6px;
                }
              }
            }
          }
        }

        .stats-title {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 49px;
        }

        .stats-body {
          margin-top: 20px;
        }
      }
    }

    @include max-tablet() {
      margin-left: -12px;
      margin-right: -12px;
    }

    @include max-laptop() {
      margin-top: 12px;
      flex-direction: column;
      padding: 16px 12px;

      .header-column {
        width: 100%;

        &.avatar {
          display: flex;
          align-items: center;

          .user-avatar {
            width: 60px;
            height: 60px;
          }

          .user-nick {
            display: block;
            font-size: 24px;
            font-weight: bold;
            margin-left: 12px;
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .user-icon {
            display: block;
          }
        }

        &.info {
          margin-left: 0;

          .profile-info-list {
            margin-top: 12px;
          }

          .user-nick {
            display: none;
          }

          .user-icon {
            display: none;
          }
        }

        &.stats {
          &.placeholder {
            flex: 0 0 100%;
            margin-left: 0;
            border-left: none;

            .placeholder-icon {
              display: none;
            }

            .placeholder-text {
              margin-top: 0;
            }
          }

          &.matches {
            margin-left: 0;
          }

          &.tournaments {
            width: 100%;
            border-left: none;
            padding-left: 0;
            margin-left: 0;
          }

          .stats-title {
            height: auto;
          }

          .stats-body {
            margin-top: 6px;
          }
        }
      }

      .header-column + .header-column:not(.info) {
        border-top: 1px solid rgba(white, 0.1);
        padding-top: 14px;
        margin-top: 16px;
      }
    }
  }

  .profile-tabs {
    margin-top: 30px;

    @include max-tablet() {
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  .page-content-section {
    margin-top: 30px;
  }
}

.profile-headline {
  display: flex;
  justify-content: space-between;
  @include max-laptop() {
    flex-direction: column;
    .profile-socials {
      order: 1;
      margin-bottom: 20px;
    }
    .centered {
      order: 2;
    }
  }
}

.matches-progressbar {
  font-size: 20px;
}

.user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-bottom: 30px;
}
.cyber-icon-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss" scoped></style>

<template>
  <div class="tournaments">
    <profile-tournaments :hash="hash" :show-subtitle="true" />
  </div>
</template>

<script>
import ProfileTournaments from '@components/TournamentComponents/Lists/ProfileTournaments.vue';

export default {
  name: 'Tournaments',
  components: { ProfileTournaments },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.profileHash;
    },
  },
};
</script>

<template>
  <div class="profile-teams">
    <headline class="centered spaced">
      <h3 slot="title">
        {{
          $t(`teams.variant_${isCurrentUserProfile ? 'my' : 'other'}`)
        }}
      </h3>
      <Button
        v-if="isCurrentUserProfile && teams.length"
        slot="addon"
        tag="button"
        size="regular"
        type="secondary"
        :loading="false"
        :text="$t('teams.create_button')"
        @click.native="showNewTeamModal"
      >
        <template #slotBefore>
          <icon name="plus" />
        </template>
      </Button>
    </headline>

    <Table v-if="teams.length">
      <template #thead>
        <tr>
          <th v-for="(header, idx) in tableHeaders" :key="idx">
            {{ header }}
          </th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="team in teams" :key="team.hash">
          <td>
            <BaseTeamLink :hash="team.hash" />
          </td>
          <td>
            <game-tag :id="team.idGame" />
          </td>
          <td>
            {{ team.size }}
          </td>
          <td>
            {{ team.memberType }}
          </td>
          <td>
            <Button
              v-if="isCurrentUserProfile"
              tag="button"
              size="regular"
              type="tertiary"
              :loading="false"
              :text="
                $t(
                  team.role === 'captain'
                    ? 'global.edit'
                    : 'teams.leave_title',
                )
              "
              @click.native="openTeamModal(team)"
            />
          </td>
        </tr>
      </template>
    </Table>
    <div v-else class="empty">
      <template v-if="isCurrentUserProfile">
        <div class="empty-title">
          {{ $t('teams.create_first') }}
        </div>
        <Button
          slot="addon"
          tag="button"
          size="regular"
          type="secondary"
          :loading="false"
          :text="$t('teams.create_button')"
          @click.native="showNewTeamModal"
        >
          <template #slotBefore>
            <icon name="plus" />
          </template>
        </Button>
      </template>

      <div v-else class="empty-title">
        {{ $t('teams.no-teams') }}
      </div>
    </div>

    <template v-if="isCurrentUserProfile">
      <new-team-modal v-model="newTeamModalOpened" />
      <edit-team-modal
        v-model="isOpenEditModal"
        :hash="actualTeam.hash"
      />
      <leave-team-modal
        v-if="teamLeaveModel"
        v-model="teamLeaveModel"
        :team="actualTeam"
        @leaveTeam="refreshTeamsData"
      />
    </template>
  </div>
</template>

<script>
import NewTeamModal from '@components/TeamComponents/TeamManagement/NewTeamModal.vue';
import EditTeamModal from '@components/TeamComponents/EditTeamModal.vue';
import LeaveTeamModal from '@components/TeamComponents/LeaveTeamModal.vue';
import Table from '@src/components/v2/Table.vue';
import Button from '@components/v2/ui/Button.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Teams',
  components: {
    NewTeamModal,
    EditTeamModal,
    LeaveTeamModal,
    Table,
    Button,
    BaseTeamLink,
    Icon,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newTeamModalOpened: false,
      isOpenEditModal: false,
      teamLeaveModel: false,
      actualTeam: '',
      tableHeaders: [
        this.$t('teams.single'),
        this.$t('games.single'),
        this.$t('players.count'),
        this.$t('players.role_single'),
        '',
      ],
    };
  },
  computed: {
    ...mapGetters('users', ['getUserTeams']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.hash;
    },

    teams() {
      const teams = this.getUserTeams(this.hash);

      const memberTypes = {
        captain: this.$t('players.role_captain'),
        player: this.$t('players.single'),
        spare: this.$t('players.role_reserve'),
      };

      return (
        teams?.map((team) => ({
          ...team,
          memberType: memberTypes[team.role],
        })) || []
      );
    },
  },
  methods: {
    ...mapActions('users', ['fetchUserProfile']),

    showNewTeamModal() {
      this.newTeamModalOpened = true;
    },

    openTeamModal(team) {
      this.actualTeam = team;
      if (team.role === 'captain') {
        this.isOpenEditModal = true;
      } else {
        this.teamLeaveModel = true;
      }
    },

    refreshTeamsData() {
      this.fetchUserProfile({
        hash: this.getCurrentProfileHash,
        complex: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  margin-top: 30px;
  margin-bottom: 18px;
  @include max-tablet() {
    margin-top: 6px;
  }
}

.pvp-table.adaptive {
  @include max-laptop() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .table-body {
    @include max-laptop() {
      padding-left: 12px;
      padding-right: 12px;
    }

    .table-column {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 3fr 2.5fr 1.5fr 1fr 180px;
    }
  }

  &-column {
    @include max-laptop() {
      ::v-deep .column-title {
        width: 110px;
        flex-shrink: 0;
      }
    }

    &.controls {
      @include min-laptop() {
        justify-content: flex-end;
      }
      @include max-laptop() {
        ::v-deep .column-title {
          width: 0;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}

.empty {
  text-align: center;
  background-color: $dark-background;
  border-radius: 3px;
  box-shadow: $default-box-shadow;
  padding-top: 30px;
  padding-bottom: 30px;
  @include max-tablet() {
    padding: 30px 12px;
  }

  &-action {
    margin-top: 12px;
  }
}

.empty-title {
  margin-bottom: 16px;
}
</style>

<template>
  <div class="table-wrapper">
    <table class="table">
      <thead
        class="table__header"
        :style="{ 'background-color': headerBgColor }"
      >
        <slot name="thead" />
      </thead>
      <tbody class="table__body">
        <slot name="tbody" />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    headerBgColor: {
      type: String,
      default: '#2d2d30',
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  overflow: auto;
  border-radius: 6px;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.table__header {
  background: #2d2d30;
  color: #979797;
}

.table__header th {
  padding: 12px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &:first-child {
    text-align: left;
  }
}

.table__body {
  color: #f5f5f7;
}

.table__body tr {
  &:nth-child(odd) {
    background: #100f0f;
  }
  &:nth-child(even) {
    background: #161516;
  }
}

.table__body td {
  padding: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #f5f5f7;
  vertical-align: top;

  &:not(:first-child):not([class]) {
    text-align: center;
  }

  &.table__data--align-right {
    text-align: right;
  }
}

.table__header th,
.table__body td {
  box-sizing: border-box;
}

.table__accent {
  &--max {
    width: 100%;
  }
  &--mid {
    min-width: 300px;
  }
  &--small {
    min-width: 100px;
  }
  &--little {
    min-width: 50px;
  }
}
</style>
